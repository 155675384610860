import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import CountUpComponent from "./CountUpComponent";
import "../App.css";
import AOS from "aos";
import "aos/dist/aos.css";
function ShowCountUp() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Box
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-easing="easeInOutSine"
      data-aos-frameRate="60"
      mt="20"
      mb={{ base: "6", sm: "12", md: "20", lg: "20" }}
      className="show-count-up"
      backgroundImage="url('https://cdn.pixabay.com/photo/2018/12/10/16/22/city-3867295_1280.png')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      justifyContent="center"
      alignItems="center"
      py={16}
      width="100%"
    >
      <CountUpComponent value={7} label="Live Projects 2023" />
      <CountUpComponent value={13} label="Years of Experience" />
      <CountUpComponent value={18} label="Projects Completed" />
      <CountUpComponent value={100} label="Satisfied Clients" />

      <CountUpComponent value={5000} label="JK Cement Bags Sold" />
    </Box>
  );
}

export default ShowCountUp;
