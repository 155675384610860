import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUsContent = () => {
  return (
    <Box w="90%" m="auto">
      <Box mt={8} p={4}>
        <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
          <Text
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-easing="easeInOutSine"
            data-aos-frameRate="60"
            color={"gray.600"}
            fontSize={{ base: "lg", sm: "xl" }}
            fontFamily="Oswald"
          >
            Welcome to the World of SHRI CONSTRUCTION MULTISERVICES. For your
            satisfaction, we provide all types of residential, industrial, and
            commercial construction work, as well as construction material
            supply services.
            <br />
            <br />
            <Divider />
            <br />
            Our company is a registered government entity with certifications
            from Zilha Parishad License (ZP), Public Work Department License
            (PWD), GST Certificate, Shop Act License, Government MSME Udyog
            Adhar, and ISO 9001:2015. Our company was established in Satara in
            2018, and we have completed 8 projects up to 2023.
            <br />
            <br />
            <Divider />
            <br />
            We are authorized suppliers of JK Cement. Our office is located in
            Satara, Cooper Colony, Sadar Bazar, and we provide services in and
            around the Satara District.
            <br />
            <br />
            <Divider />
            <br />
            We have a dedicated team of professionals for all construction work.
            For marketing purposes, you can find us online on Just Dial,
            Facebook, WhatsApp, Instagram, Twitter, Facebook, and YouTube.
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default AboutUsContent;
