import React from "react";
import ServicesHeroSection from "../Components/ServicesHeroSection";
import ServicesFeatures from "../Components/ServicesFeatures";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
const Services = () => {
  return (
    <div>
      <Navbar />

      <br />
      <br />
      <br />
      <ServicesHeroSection />
      <ServicesFeatures />
      <Footer />
    </div>
  );
};

export default Services;
