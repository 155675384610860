import Navbar from "./Components/Navbar";
import HomePage from "./Pages/HomePage";
import MainRoutes from "./Pages/ManiRoutes";
import Footer from "./Components/Footer";
function App() {
  return (
    <div>
      <MainRoutes />
    </div>
  );
}

export default App;
