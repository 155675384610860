import React from "react";
import Navbar from "../Components/Navbar";
import { Box } from "@chakra-ui/react";
import ContactUsHeroSection from "../Components/ContactUsHeroSection";
import ContactUsContent from "../Components/ContactUsContent";
import Footer from "../Components/Footer";
const ContactUs = () => {
  return (
    <Box>
      <Navbar />
      <br />
      <br />
      <br />
      <ContactUsHeroSection />
      <ContactUsContent />
      <Footer />
    </Box>
  );
};

export default ContactUs;
