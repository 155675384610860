import React, { useEffect } from "react";
import { Flex, chakra, Image, Box, useBreakpointValue } from "@chakra-ui/react";
import isoCertificate from "../Assets/isoCertificate.jpg";
import justDialCertificate from "../Assets/justDialCertificate.jpg";
import jkCement from "../Assets/jkCement.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
const AboutUsCertificates = () => {
  const boxSize = useBreakpointValue({ base: "full", sm: "sm", md: "md" });

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      p={5}
      alignItems="center"
      justifyContent="center"
      wrap="wrap"
    >
      <Box
        data-aos="zoom-in"
        data-aos-duration="700"
        m={4}
        w={boxSize}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        mx="auto"
      >
        <Image w="full" src={justDialCertificate} alt="Just Dial Certificate" />

        <Box py={5} textAlign="center">
          <chakra.span
            display="block"
            fontSize={{ base: "md", md: "xl" }}
            color="gray.800"
            _dark={{
              color: "white",
            }}
            fontWeight="bold"
          >
            Just Dial Certificate
          </chakra.span>
        </Box>
      </Box>
      <Box
        data-aos="zoom-in"
        data-aos-duration="700"
        m={4}
        w={boxSize}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        mx="auto"
      >
        <Image w="full" src={jkCement} alt="Just Dial Certificate" />

        <Box py={5} textAlign="center">
          <chakra.span
            display="block"
            fontSize={{ base: "md", md: "xl" }}
            color="gray.800"
            _dark={{
              color: "white",
            }}
            fontWeight="bold"
          >
            JK Cement Authrised Dealer
          </chakra.span>
        </Box>
      </Box>
      <Box
        data-aos="zoom-in"
        data-aos-duration="700"
        m={4}
        w={boxSize}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        shadow="lg"
        rounded="lg"
        overflow="hidden"
        mx="auto"
      >
        <Image w="full" src={isoCertificate} alt="ISO Certificate" />

        <Box py={5} textAlign="center">
          <chakra.span
            display="block"
            fontSize={{ base: "md", md: "xl" }}
            color="gray.800"
            _dark={{
              color: "white",
            }}
            fontWeight="bold"
          >
            We are ISO 9001:2015 Certified Company
          </chakra.span>
        </Box>
      </Box>
    </Flex>
  );
};

export default AboutUsCertificates;
