import { Route, Routes } from "react-router-dom";

import HomePage from "./HomePage";
import Services from "./Services";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Projects from "./Projects";
import Copyright from "./Copyright";
import PrivacyPolicy from "./PrivacyPolicy";
import AdminDashboard from "./AdminDashboard";
import CurrentProjects from "../Components/CurrentProjects";

function MainRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/copyrightandterms" element={<Copyright />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      {/* <Route path="/adminprojects" element={<CurrentProjects />} />
      <Route
        path="/shriconstructionsadmindashboard"
        element={<AdminDashboard />}
      /> */}
    </Routes>
  );
}
export default MainRoutes;
