import * as React from "react";
import {
  chakra,
  Container,
  Stack,
  HStack,
  Text,
  IconButton,
  useColorModeValue,
  Button,
  Box,
  Link,
  Icon,
  Flex,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { GoChevronRight } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import {
  FaGithub,
  FaInstagram,
  FaWhatsapp,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";

const accounts = [
  {
    url: "https://wa.me/+919689735435",
    label: "Whatsapp Account",
    type: "whatsapp",
    icon: <FaWhatsapp />,
  },
  {
    url: "https://mail.google.com/mail/?view=cm&fs=1&to=<shriconstructionmultiservices@gmail.com>",
    label: "Email",
    type: "red",
    icon: <AiOutlineMail />,
  },
  {
    url: "https://www.instagram.com/shriconstructionmultiservices7/",
    label: "Instagram Account",
    type: "pink",
    icon: <FaInstagram />,
  },
  {
    url: "https://www.facebook.com/ShriConstructionMultiservices",
    label: "Facebook",
    type: "blue",
    icon: <FaFacebook />,
  },
  {
    url: "https://www.youtube.com/@ER_VISHAL_S_BALLAL",
    label: "Youtube",
    type: "red",
    icon: <FaYoutube />,
  },
];

const ContactUsContent = () => {
  return (
    <>
      <Container maxW="6xl" px={{ base: 6, md: 3 }} py={14}>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={2}
          justifyContent="center"
        >
          <Stack
            direction="column"
            spacing={6}
            justifyContent="center"
            maxW="500px"
            mb={{ base: 3, md: 0 }}
          >
            <Box>
              <chakra.h1
                fontSize="5xl"
                lineHeight={1}
                fontWeight="bold"
                textAlign="left"
              >
                <chakra.span color="teal" mr="4">
                  Connect
                </chakra.span>
                with us
              </chakra.h1>
            </Box>
            <Text
              fontSize="1.2rem"
              textAlign="left"
              lineHeight="1.375"
              fontWeight="400"
              color="gray.500"
            >
              Feel free to reach out to us for any quaries on our mobile
              number,email or social media platforms
            </Text>
          </Stack>
          <Stack
            spacing={{ base: 5, sm: 2 }}
            direction={{ base: "column", sm: "column" }}
            alignItems="left"
          >
            <Card
              heading="Our Office Address"
              detail="Shop No.2,Matoshree Plaza,Cooper Colony,Gate No.2,Sadar Bazar,Satara - 415002"
            />
            <Card heading="Contact Number" detail="+91 9689735435" />
          </Stack>
        </Stack>
      </Container>
      <Box maxW="5xl" p="10" borderRadius={4} border="#ccc solid 4px" m="auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15203.747907298235!2d74.00348891738281!3d17.700437100000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2393a64faa6c5%3A0x52a5337b0524c1a2!2sShri%20Construction%20Multisevices!5e0!3m2!1sen!2sin!4v1699324758301!5m2!1sen!2sin"
          width="100%"
          height="400"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
      <Box textAlign="center" m="6" mt="8">
        <chakra.h1
          fontSize="3xl"
          lineHeight={1}
          fontWeight="bold"
          textAlign="center"
        >
          {" "}
          Our <chakra.span color="teal">Social Media</chakra.span> Handles
        </chakra.h1>
      </Box>

      <Flex alignItems="center" justify="center" w="100%">
        <Box textAlign="center">
          {accounts.map((sc, index) => (
            <>
              <IconButton
                w={16}
                h={16}
                icon={sc.icon}
                m="6"
                key={index}
                as={Link}
                isExternal
                href={sc.url}
                aria-label={sc.label}
                colorScheme={sc.type}
                rounded="full"
                _hover={{
                  boxShadow:
                    ("0 4px 6px rgba(160, 174, 192, 0.6)",
                    "0 4px 6px rgba(9, 17, 28, 0.9)"),
                }}
              />
            </>
          ))}
        </Box>
      </Flex>
    </>
  );
};

const Card = ({ heading, detail }) => {
  return (
    <Stack
      direction="column"
      _hover={{
        boxShadow: useColorModeValue(
          "0 4px 6px rgba(160, 174, 192, 0.6)",
          "0 4px 6px rgba(9, 17, 28, 0.9)"
        ),
      }}
      bg={useColorModeValue("gray.200", "gray.700")}
      p={3}
      rounded="lg"
      spacing={1}
      maxW="450px"
      h="max-content"
    >
      <Text fontSize="lg" fontWeight="semibold">
        {heading}
      </Text>
      <Text
        fontSize="md"
        textAlign="left"
        color="gray.500"
        lineHeight={1.3}
        noOfLines={2}
      >
        {detail}
      </Text>
    </Stack>
  );
};
export default ContactUsContent;
