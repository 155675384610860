import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Button,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

export default function SplitScreen() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Stack minH={"90vh"} direction={{ base: "column", md: "row" }}>
        <Flex
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          p={8}
          flex={1}
          align={"center"}
          justify={"center"}
        >
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: useBreakpointValue({ base: "20%", md: "30%" }),
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "blue.400",
                  zIndex: -1,
                }}
              >
                Expert
              </Text>
              <br />{" "}
              <Text color={"blue.400"} as={"span"}>
                Team of Engineers
              </Text>{" "}
            </Heading>
            <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
              At Shri Construction and Multiservices, we have Experienced and
              Experts in the field ,who Delivered multiple Projects with great
              Quality of Construction
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <Button
                rounded={"full"}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.400",
                }}
              >
                Check Our Projects
              </Button>
              <Button rounded={"full"}>Services We Offer</Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          flex={1}
        >
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            src={
              "https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_1280.jpg"
            }
          />
        </Flex>
      </Stack>{" "}
      <Stack minH={"90vh"} direction={{ base: "column", md: "row" }}>
        <Flex
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          flex={1}
        >
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            src={
              "https://images.unsplash.com/photo-1613977257592-4871e5fcd7c4?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
          />
        </Flex>{" "}
        <Flex
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          p={8}
          flex={1}
          align={"center"}
          justify={"center"}
        >
          <Stack spacing={6} w={"full"} maxW={"lg"}>
            <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "full",
                  height: useBreakpointValue({ base: "20%", md: "30%" }),
                  position: "absolute",
                  bottom: 1,
                  left: 0,
                  bg: "blue.400",
                  zIndex: -1,
                }}
              >
                You Imagine
              </Text>
              <br />{" "}
              <Text color={"blue.400"} as={"span"}>
                We will Buid for you
              </Text>{" "}
            </Heading>
            <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
              We thrive on turning your dreams into reality. When you choose us
              as your construction partner, you're not just selecting a
              contractor; you're selecting a team of dedicated professionals who
              share your vision and understand your aspirations.
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing={4}>
              <Button
                rounded={"full"}
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.300",
                }}
              >
                Contact Us
              </Button>
              <Button rounded={"full"}>About us</Button>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    </>
  );
}
