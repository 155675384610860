import React from "react";
import { Flex, Box, Image, Link, chakra } from "@chakra-ui/react";

const ProjectsContent = () => {
  // const projectsData = [
  //   {
  //     title: "Jaggery Factory Construction",
  //     image: "",
  //     desc: "construction demo project",
  //   },
  //   { title: "Kay Bouvet Site", image: "", desc: "construction demo project" },
  //   { title: "abcd", image: "", desc: "construction demo project" },
  //   { title: "abcd", image: "", desc: "construction demo project" },
  // ];
  return (
    <div>
      <Flex
        bg="#edf3f8"
        _dark={{
          bg: "#3e3e3e",
        }}
        p={50}
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          bg="white"
          _dark={{
            bg: "gray.800",
          }}
          mx={{
            lg: 8,
          }}
          display={{
            lg: "flex",
          }}
          maxW={{
            lg: "5xl",
          }}
          shadow={{
            lg: "lg",
          }}
          rounded={{
            lg: "lg",
          }}
        >
          <Box
            w={{
              lg: "50%",
            }}
          >
            <Box
              h={{
                base: 64,
                lg: "full",
              }}
              rounded={{
                lg: "lg",
              }}
            >
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/9i-STEmlzqs?si=BD0UumPFjBcLnyfi"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Box>
          </Box>

          <Box
            py={12}
            px={6}
            maxW={{
              base: "xl",
              lg: "5xl",
            }}
            w={{
              lg: "50%",
            }}
          >
            <chakra.h2
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color="gray.800"
              _dark={{
                color: "white",
              }}
              fontWeight="bold"
            >
              Jaggary Factory Civil Foundation Work
            </chakra.h2>
            <chakra.p
              mt={4}
              color="gray.600"
              _dark={{
                color: "gray.400",
              }}
            >
              The Jaggary Factory Civil Foundation Work project involves the
              construction of a new jaggery factory near satara. The project
              includes the following scope of work: Excavation and earthwork
              Formwork and shuttering Concrete work Reinforcement work
            </chakra.p>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default ProjectsContent;
