import { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FcAbout,
  FcAssistant,
  FcCollaboration,
  FcDonate,
  FcAdvance,
  FcManager,
  FcOvertime,
} from "react-icons/fc";
import { BsFillBuildingFill } from "react-icons/bs";
import { MdSentimentSatisfiedAlt } from "react-icons/md";
import { GiDuration } from "react-icons/gi";

const Card = ({ heading, description, icon, href }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Box
      bgColor="white"
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="easeInOutSine"
            data-aos-frameRate="60"
            size="md"
          >
            {heading}
          </Heading>
          <Text
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-easing="easeInOutSine"
            data-aos-frameRate="60"
            mt={1}
            fontSize={"sm"}
          >
            {description}
          </Text>
        </Box>
        {/* <Button variant={"link"} colorScheme={"blue"} size={"sm"}>
          Learn more
        </Button> */}
      </Stack>
    </Box>
  );
};

export default function Features() {
  return (
    <Box p={4} bgColor="#e6f5e6">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
          fontWeight={"bold"}
          fontFamily="Raleway"
        >
          Our Vision
        </Heading>
        <Text
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          color={"gray.600"}
          fontSize={{ base: "sm", sm: "lg" }}
          fontFamily="Oswald"
        >
          To add Value for our Customers , To establish ourselves as the
          foremost industry leader in engineering construction, driven by market
          demands, and renowned for our unwavering commitment to excellence,
          quality, performance, and reliability in every facet of construction
        </Text>
      </Stack>

      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={"Quality Construction"}
            icon={<Icon fill="black" as={BsFillBuildingFill} w={10} h={10} />}
            description={
              "Our commitment to quality is at the heart of every project we undertake.Quality is not just a goal; it's our unwavering promise. "
            }
          />
          <Card
            bgColor="white"
            heading={"Innovation and Technology"}
            icon={<Icon as={FcAdvance} w={10} h={10} />}
            description={
              "We embrace cutting-edge technology and innovative construction methods to enhance the efficiency and precision of our work"
            }
            href={"#"}
          />
          <Card
            bgColor="white"
            heading={"Customer Satisfaction:"}
            icon={
              <Icon fill="green" as={MdSentimentSatisfiedAlt} w={10} h={10} />
            }
            description={
              " We prioritize open communication, transparent processes, and a customer-centric approach to ensure your vision becomes a reality, exceeding your expectations."
            }
            href={"#"}
          />
          <Card
            bgColor="white"
            heading={"Durability"}
            icon={<Icon fill="green" as={GiDuration} w={10} h={10} />}
            description={
              "Building for longevity is a core principle in our construction philosophy. We construct with the future in mind, creating structures that withstand the test of time."
            }
            href={"#"}
          />
          <Card
            heading={"Timely Project Delivery"}
            icon={<Icon as={FcOvertime} w={10} h={10} />}
            description={
              "We understand the importance of time in construction. Our team is dedicated to delivering your project on schedule, without compromising on quality."
            }
            href={"#"}
          />
        </Flex>
      </Container>
    </Box>
  );
}
