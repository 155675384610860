import { useEffect } from "react";
import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AboutUsHeroSection() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Flex
      w={"full"}
      h={"60vh"}
      backgroundImage={
        "url(https://images.unsplash.com/photo-1541888946425-d81bb19240f5?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)"
      }
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
      >
        <Stack maxW={"2xl"} align={"flex-start"} spacing={8}>
          <Text
            color={"white"}
            fontWeight={700}
            lineHeight={1.2}
            fontFamily="Raleway"
            fontSize={useBreakpointValue({ base: "5xl", md: "7xl" })}
          >
            About Us
          </Text>
        </Stack>
      </VStack>
    </Flex>
  );
}
