import React from "react";
import Navbar from "../Components/Navbar";
import AboutUsHeroSection from "../Components/AboutUsHeroSection";
import AboutUsContent from "../Components/AboutUsContent";
import Footer from "../Components/Footer";
import FounderInfo from "../Components/FounderInfo";
import AboutUsCertificates from "../Components/AboutUsCertificates";
const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <AboutUsHeroSection />
      <AboutUsContent />
      <AboutUsCertificates />
      <FounderInfo />
      <Footer />
    </div>
  );
};

export default AboutUs;
