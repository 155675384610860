import React from "react";
import { Box, Text, Link } from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <Box m="12">
      <Text fontWeight="bold">Privacy Policy</Text>
      <br />
      <Text>
        We at Shri Construction and Multiservices are committed to respecting
        your privacy. We do not collect any personal information from you when
        you visit our website or use our services.
      </Text>
      <br />
      <Text fontWeight="bold">Third-Party Services</Text>
      <Text>
        Our website uses third-party services, such as Google Analytics, to
        collect anonymous data about how visitors use our website. This data is
        used to improve our website and services. We do not share any personal
        information with these third-party services.
      </Text>
      <br />
      <Text fontWeight="bold">What third-party services do we use?</Text>
      <Text>We use the following third-party services on our website:</Text>
      <ul>
        <li>Google Analytics</li>
      </ul>
      <br />
      <Text fontWeight="bold">
        What data do these third-party services collect?
      </Text>
      <Text>
        The third-party services that we use collect anonymous data about how
        visitors use our website. This data may include the pages that visitors
        view, the links that they click on, and the amount of time that they
        spend on our website.
      </Text>
      <br />
      <Text fontWeight="bold">
        How do we use the data that these third-party services collect?
      </Text>
      <Text>
        We use the data that we collect from third-party services to improve our
        website and services. For example, we may use this data to identify the
        most popular pages on our website or to understand how visitors are
        using our website.
      </Text>
      <br />
      <Text fontWeight="bold">
        Do we share any personal information with third-party services?
      </Text>
      <Text>
        No, we do not share any personal information with third-party services.
      </Text>
      <br />
      <Text fontWeight="bold">Cookies</Text>
      <Text>
        Our website doesn't use cookies to improve your experience. Cookies are
        small files that are stored on your computer by our website. They are
        used to track your preferences, such as the pages you have visited and
        the language you have selected.
      </Text>
      <br />
      <Text fontWeight="bold">Links to Third-Party Websites</Text>
      <Text>
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices of these websites. We encourage
        you to read the privacy policies of these websites before providing them
        with any personal information.
      </Text>
      <br />
      <Text fontWeight="bold">Contact Us</Text>
      <Text>
        If you have any questions about this Privacy Policy, please contact us
      </Text>
      <br />
      <Text>
        <Link to="copyrightandterms">Terms of Service</Link>
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
