import React, { useState, useEffect } from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

export default function CountUpComponent({ value, label }) {
  const [count, setCount] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      let currentCount = 0;
      const step = value / 60;

      const counterInterval = setInterval(() => {
        if (currentCount < value) {
          setCount(currentCount);
          currentCount += step;
        } else {
          setCount(value);
          clearInterval(counterInterval);
        }
      }, 16);
    }
  }, [inView, value]);

  return (
    <Center
      flexDirection="column"
      borderRadius="md"
      w="80%"
      ref={ref}
      initial={{ scale: 0.5, opacity: 0 }}
      animate={animation}
    >
      <Text
        fontSize={{ base: "3xl", sm: "3xl", md: "4xl", lg: "6xl" }}
        fontWeight="bold"
        color="black"
      >
        {Math.floor(count)}+
      </Text>
      <Text
        fontSize={{ base: "2xl", sm: "2xl", md: "2xl", lg: "2xl", xl: "2xl" }}
        fontWeight="semibold"
        color="black.300"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
      >
        {label}
      </Text>
    </Center>
  );
}
