import React from "react";
import { Box, Text } from "@chakra-ui/react";
const Copyright = () => {
  return (
    <Box m="12">
      <Text fontWeight="bold">Copyright Notice</Text> <br />© 2023 Shri
      Construction and Multiservices. All rights reserved. All content on this
      website, including text, graphics, logos, images, and audio clips, is the
      sole property of Shri Construction and Multiservices and is protected by
      Indian and international copyright laws.
      <Text fontWeight="bold">Terms of Use</Text> By accessing and using this
      website, you agree to the following terms of use: <br />
      <Text fontWeight="semibold">Purpose of the Website </Text>
      This website is provided for informational purposes only and does not
      constitute a commercial offer, a license, or an advisory, fiduciary, or
      professional relationship between you and Shri Construction and
      Multiservices.
      <Text fontWeight="semibold">Intellectual Property </Text>
      All content on this website is the exclusive property of Shri Construction
      and Multiservices or its licensors and is protected by Indian and
      international copyright laws. You may use this content for personal and
      non-commercial purposes only. Any other use or modification of the content
      of this website without the prior written permission of Shri Construction
      and Multiservices is prohibited.
      <Text fontWeight="semibold">Disclaimer of Warranties and Liability</Text>
      Shri Construction and Multiservices makes no warranties, express or
      implied, as to the accuracy, reliability, or completeness of the
      information on this website. Shri Construction and Multiservices is not
      liable for any damages, direct or indirect, arising out of the use of this
      website or the information contained therein.
      <Text>Links to Third-Party Websites</Text>
      This website may contain links to third-party websites. Shri Construction
      and Multiservices is not responsible for the content or privacy practices
      of any third-party websites.
      <Text fontWeight="semibold">Governing Law </Text>These terms of use are
      governed by and construed in accordance with the laws of India. Any
      disputes arising out of or in connection with these terms of use shall be
      subject to the exclusive jurisdiction of the courts of Mumbai, India.
      <Text fontWeight="semibold">Changes to the Terms of Use</Text>
      Shri Construction and Multiservices reserves the right to change these
      terms of use at any time without notice. You are responsible for reviewing
      the terms of use periodically for any changes. Your continued use of this
      website after any changes to the terms of use constitutes your acceptance
      of such changes.
    </Box>
  );
};

export default Copyright;
