import { useEffect } from "react";
import identitySizePhoto from "../Assets/identitySizePhoto.jpg";
import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";

const BlogTags = (props) => {
  const { marginTop = 0, tags } = props;

  return (
    <HStack spacing={2} marginTop={marginTop}>
      {tags.map((tag) => {
        return (
          <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

const FounderInfo = () => {
  return (
    <Container maxW={"7xl"} p="12">
      <Heading
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-easing="easeInOutSine"
        data-aos-frameRate="60"
        as="h1"
        fontFamily="sans-serif"
      >
        About Founder
      </Heading>
      <Box
        marginTop={{ base: "1", sm: "5" }}
        display="flex"
        flexDirection={{ base: "column", sm: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center"
        >
          <Box
            width={{ base: "100%", sm: "85%" }}
            zIndex="2"
            marginLeft={{ base: "0", sm: "5%" }}
            marginTop="5%"
          >
            <Box
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-easing="easeInOutSine"
              data-aos-frameRate="60"
              textDecoration="none"
              _hover={{ textDecoration: "none" }}
            >
              <Image
                borderRadius="lg"
                src={identitySizePhoto}
                alt="some good alt text"
                objectFit="contain"
              />
            </Box>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                "radial(orange.600 1px, transparent 1px)",
                "radial(orange.300 1px, transparent 1px)"
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="easeInOutSine"
          data-aos-frameRate="60"
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: "3", sm: "0" }}
        >
          {/* <BlogTags tags={["Engineering", "Product"]} /> */}
          <Heading marginTop="1">
            <Text
              fontFamily="revert"
              textDecoration="none"
              color="purple.600"
              _hover={{ textDecoration: "none" }}
            >
              Er. Vishal Ballal
            </Text>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue("gray.700", "gray.200")}
            fontSize="lg"
          >
            I am a seasoned Civil Engineer with a Diploma in Civil Engineering
            (DCE) and appearing in Bachelor of Technology (B.Tech) degree in
            Civil Engineering. With over 14 years of experience in the
            construction industry, I possess extensive technical knowledge and
            expertise in various aspects of civil engineering. <br />
            My work primarily revolves around implementing smart and efficient
            construction methods through the utilization of advanced machinery
            and equipment. One of my core strengths lies in meticulous project
            planning, ensuring that projects are completed on schedule while
            maintaining a high standard of quality.
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default FounderInfo;
