import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  SimpleGrid,
  Button,
  Text,
  Heading,
  Image,
  chakra,
} from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { CheckCircleIcon } from "@chakra-ui/icons";

const ServicesFeatures = () => {
  const [services, setServices] = useState([]);

  const data = [
    "JK Cement Bags Authorised Stockist",
    "All Type Waterproofing Work",
    "Project planning",
    "Cost estimation",
    "Contracting",
    "Design review",
    "Permitting",
    "Commercial construction",
    "Residential construction",
    "Industrial construction",
    "Building maintenance",
    "Design services",
    "Engineering services",
    "Landscaping services",
  ];
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    // Fetch services data from your API and update the state
    fetchServicesFromAPI()
      .then((data) => {
        setServices(data);
      })
      .catch((error) => {
        console.error("Error fetching services: ", error);
      });
  }, []);

  const fetchServicesFromAPI = async () => {
    // Replace this with your actual API call to fetch services data
    // Example: const response = await fetch('your_api_endpoint');
    // const data = await response.json();
    // return data;
    return [
      {
        title: "Pre-construction services",
        description:
          "We provide project planning to help our clients develop a detailed plan for their project, including timelines, budgets, and resource allocation.We provide cost estimation to provide our clients with accurate estimates of the cost of their project. ",
        imageUrl:
          "https://images.unsplash.com/photo-1610650394144-a778795cf585?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        title: "Construction management",
        description:
          "This service involves overseeing the entire construction process, from start to finish. This includes hiring subcontractors, managing budgets, and ensuring that the project is completed on time and within budget.",
        imageUrl:
          "https://images.unsplash.com/photo-1611906622516-be9a76360430?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
      {
        title: "Contracting",
        description:
          "We provide general contracting to take responsibility for all aspects of the construction project, from pre-construction to completion. We serve as the single point of contact for our clients on all aspects of the project, and we coordinate the activities of all subcontractors and suppliers.",
        imageUrl:
          "https://www.injuredoverseas.com/wp-content/uploads/2017/10/Policy-Changes-Spur-Indian-Private-Military-Contractor-Expansion.jpg",
      },
      {
        title: "Maintenance and repair",
        description:
          "We offer preventive and corrective maintenance services for buildings, including roof repairs, painting, and HVAC maintenance,also we provide maintenance services for houses,apartments.",
        imageUrl:
          "https://images.unsplash.com/photo-1534398079543-7ae6d016b86a?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      },
    ];
  };

  return (
    <div>
      <Flex
        bg="#ebf8ff"
        p={{ base: 10, md: 20 }}
        w="full"
        justifyContent="center"
        alignItems="center"
      >
        <Box bg="#FAF089" shadow="lg" px={8} py={20} mx="auto">
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
            }}
            mb={24}
            spacingY={{
              base: 10,
              md: 32,
            }}
            spacingX={{
              base: 10,
              md: 24,
            }}
          >
            {services.map((service, index) => (
              <Box key={index}>
                <chakra.h2
                  data-aos="zoom-in"
                  data-aos-duration="700"
                  mb={4}
                  fontSize={{
                    base: "lg",
                    sm: "xl",
                    md: "4xl",
                  }}
                  fontWeight="bold"
                  letterSpacing="wide"
                  textAlign={{
                    base: "left",
                    md: "left",
                  }}
                  color="gray.900"
                  lineHeight={{
                    md: "shorter",
                  }}
                  textShadow="2px 0 currentcolor"
                >
                  {service.title}
                </chakra.h2>
                <Box>
                  <Image
                    data-aos="fade-right"
                    data-aos-duration="700"
                    w="100%"
                    h="100%"
                    src={service.imageUrl}
                    alt="service image"
                  />
                </Box>

                <chakra.p
                  mt={5}
                  mb={5}
                  textAlign={{
                    base: "left",
                    sm: "left",
                  }}
                  color="gray.600"
                  fontSize={{
                    md: "lg",
                  }}
                  data-aos="fade-right"
                  data-aos-duration="700"
                >
                  {service.description}
                </chakra.p>
              </Box>
            ))}
          </SimpleGrid>
          <Box>
            <Heading>Also We Provide</Heading>
            <Box bg="#FAF089" w="80%" mt="10">
              <List spacing={5}>
                {data.map((item, index) => (
                  <ListItem
                    key={index}
                    fontSize="20px"
                    fontWeight="bold"
                    lineHeight="2"
                    data-aos="zoom-in"
                    data-aos-duration="700"
                  >
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    {item}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

export default ServicesFeatures;
